import { api } from "./api";
import { setAuth } from "../authSlice";

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: "/auth/login",
        method: "POST",
        body: data
      }),
      transformResponse: (response) => response.data,
      async onQueryStarted(
        arg,
        {
          dispatch,
          queryFulfilled,
        }) {
          try {
            await queryFulfilled.then(({ data }) => {
              localStorage.setItem("token", data.access_token);
              dispatch(setAuth(true));
            });
          } catch (err) {
            console.log("Error: login", err);
          }
        },
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/auth/logout",
        method: "DELETE",
      }),
      async onQueryStarted(
        arg,
        {
          dispatch,
          getState,
          extra,
          requestId,
          queryFulfilled,
          getCacheEntry,
          updateCachedData,
        }) {
          try {
            await queryFulfilled.then(() => {
              localStorage.removeItem("token");
              dispatch(setAuth(false));
            });
          } catch {
            console.error("Error: logout");
          }
        },
    }),
  }),
  overrideExisting: false,
});

export const { useLoginMutation, useLogoutMutation } = authApi;
import { api } from "./api";

export const calendarApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCalendar: builder.query({
      query: (data) => ({
        url: `/mobile/demand-calendar/${data?.url}`,
        method: "POST",
        body: data.data,
      }),
      // configuration for an individual endpoint, overriding the api setting
      keepUnusedDataFor: 300,
      transformResponse: (response) => response.data,
      providesTags: ["calendar"],
    }),
    saveSelfPrice: builder.mutation({
      query: (data) => ({
        url: `/revenue/self-price/${data?.url}`,
        method: "POST",
        body: data.data,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["calendar"],
    }),
    sendPrices: builder.mutation({
      query: (data) => ({
        url: `/integrations/send-rate-plans/${data.url}`,
        method: "POST",
        body: data.data,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: [],
    }),
  }),
  overrideExisting: false,
});

export const { useGetCalendarQuery, useSaveSelfPriceMutation, useSendPricesMutation } = calendarApi;
import { api } from "./api";
import { setAuth } from "../authSlice";

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => ({
        url: "/user",
        method: "GET",
      }),
      // configuration for an individual endpoint, overriding the api setting
      keepUnusedDataFor: 60,
      transformResponse: (response) => response.data,
      async onQueryStarted(
        arg,
        {
          dispatch,
          queryFulfilled
        }) {
          try {
            await queryFulfilled;
          } catch (err) {
            if (err?.error?.status === 400) {
              dispatch(setAuth(false));
            }
          }
        },
    }),
  }),
  overrideExisting: false,
});

export const { useGetUserQuery } = userApi;
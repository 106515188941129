import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import moment from "moment";
import { useLoginMutation } from "../../redux/features/api/authApi";
import { Grid, Space, Form, Button, Input, NoticeBar } from "antd-mobile/es";
import IconLogo from "./../../img/logo.svg";
import "./style.css";

const Login = (props) => {
  const { t, i18n } = useTranslation();
  const [ login, setLogin ] = useState('');
  const [ password, setPassword ] = useState('');

  const [ doLogin, { errorAuth } ] = useLoginMutation({
    selectFromResult: ({ error }) => ({
      errorAuth: error
    })
  });

  useEffect(() => {
    document.title = `hotellab - ${t("login")}`;
  }, [i18n.language]);

  const selectLanguage = id => {
    if (id !== i18n.language) {
      i18n.changeLanguage(id);
      moment.locale(id === "en" ? "en-gb" : id);

    if (id === "ru") {
      moment.updateLocale("ru", {
        weekdaysShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"]
      });
      moment.updateLocale("ru", {
        months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]
      });
     }
    }
  };

  return (<div className="login">
  <Grid columns={1}>
    <img className="login-logo" src={IconLogo} alt="Logo Hotellab"/>

    {errorAuth?.status === 422 &&
      <NoticeBar color="alert" content={`Error: ${t("loginIncorrect")}`} />}

    <Form>
      <Form.Item name="login" label={t("emailOrUsername")}>
        <Input
          type="text"
          clearable
          value={login}
          onChange={(v) => setLogin(v)}/>
      </Form.Item>
      <Form.Item name="password" label={t("password")}>
        <Input
          type="password"
          clearable
          value={password}
          onChange={(v) => setPassword(v)}/>
      </Form.Item>
    </Form>

    <div className="login-forgot">
      <a
        className="login-forgot-link"
        href="#">
        {t("forgotPass")}
      </a>
    </div>
  
    <div style={{ width: "100%" }}>
      <Button
        block
        size="large"
        color="primary"
        className="login-button"
        onClick={() => doLogin({ username: login, password }).unwrap()}>
        {t("signin").toUpperCase()}
      </Button>
    </div>

    <p className="login-reg">{t("firstInHL")} <a
      className="login-reg-link"
      href={i18n.language.indexOf("ru") !== -1 ?
        "https://hotellab.io/ru/contacts"
        :
        "https://hotellab.io/en/contacts"}>
      {t("reg")}!</a>
    </p>

    <Space className="login-lang-wrapper" justify="center">
      <Button
        fill="none"
        color="default"
        className={classNames({
          "login-lang-button": true,
          "active": i18n.language.indexOf("ru") !== -1
        })}
        onClick={() => selectLanguage("ru")}>Русский</Button>
      <Button
        fill="none"
        color="default"
        className={classNames({
          "login-lang-button": true,
          "active": i18n.language.indexOf("ru") === -1
        })}
        onClick={() => selectLanguage("en")}>English</Button>
    </Space>

    <Space wrap justify="around">
      { [
          {
            id: "policy",
            title: t("privPolicy"),
            ru: "https://hotellab.io/ru/content/privacy-policy",
            en: "https://hotellab.io/en/content/privacy-policy"
          },
          {
            id: "contact",
            title: t("contactUs"),
            ru: "https://hotellab.io/ru/contacts",
            en: "https://hotellab.io/en/contacts"
          }
        ].map((item) => (
          <a
            key={item.id}
            className="login-info-link"
            href={i18n.language === "ru" || i18n.language === "ru-RU" ? item.ru : item.en}>
            {item.title}
          </a>
        ))}
    </Space>
  </Grid>
</div>);
}

export default Login;

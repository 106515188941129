import React from "react";
import "./style.css";

const VisualBlock = ({ minWidth = "60px", height = "25px", value }) => {
  const getGradientStyle = (value) => {
    if (value > 70) {
      return `linear-gradient(to right, #cef8f1, #cef8f1 ${`${value}%`}, #fff ${`${value}%`}`
    } else if (value > 30 && value <=70) {
      return `linear-gradient(to right, #fbf6cd, #fbf6cd ${`${value}%`}, #fff ${`${value}%`}`
    } else {
      return `linear-gradient(to right, #fbcdd6, #fbcdd6 ${`${value}%`}, #fff ${`${value}%`}`
    }
  }

  return (
    <div
      className="visual-block"
      style={{
        minWidth,
        height,
        background: getGradientStyle(value || 0)
      }}
    />
  );
};

export default VisualBlock;

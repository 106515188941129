import { createSlice } from "@reduxjs/toolkit";
import { userApi } from "./api/userApi";

const initialState = {
  isAuth: false,
  authPending: true,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.isAuth = action.payload;
      state.authPending = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(userApi.endpoints.getUser.matchFulfilled, (state, action) => {
      state.isAuth = true;
      state.authPending = false;
    });
  },
});

// Export actions
export const { setAuth } = authSlice.actions;

// Select state auth from slice
export const status = (state) => state.auth.isAuth;
export const pending = (state) => state.auth.authPending;

// Export reducer
export default authSlice.reducer;
const formatMoney = v => {
  if (typeof v === 'string' && v.includes(",")) {
    v.replace(",", ".");
  }
  if (!isNaN(v) && !isNaN(parseFloat(v))) {
    return parseFloat(v).toFixed().replace(/\d(?=(\d{3})+$)/g, "$& ");
  }
};

export { formatMoney };

import React from "react";
import uniqid from "uniqid";
import { Space } from "antd-mobile/es";
import "./style.css";

const ColorIndicator = ({ width }) => {
  const items = [
    {
      color: "#fbcdd6",
      value: "0 - 30%"
    },
    {
      color: "#fbf6cd",
      value: "31 - 70%"
    },
    {
      color: "#cef8f1",
      value: "71 - 100%"
    },
  ];

  return (
    <Space className="color-ind" justify="center" style={{ width: "100%" }}>
      {items.map((item) => <Space key={uniqid()} direction="vertical" block>
        <div className="ci-value">{item.value}</div>
        <div className="ci-rect" style={{ backgroundColor: item.color }} />
      </Space>)}
    </Space>
  );
};

export default ColorIndicator;

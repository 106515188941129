import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import classNames from "classnames";
import uniqid from "uniqid";
import _ from "lodash";
import { formatMoney } from "./../../helpers/utils";
import { usePrevious } from "../../helpers/customHooks";
import {
  useGetCalendarQuery,
  useSaveSelfPriceMutation,
  useSendPricesMutation
} from "../../redux/features/api/calendarApi";
import {
  hotelInfo,
  hotelId,
  hotelIntegrations
} from "../../redux/features/currentSlice";
import {
  List,
  Button,
  Card,
  Stepper,
  NoticeBar,
  DotLoading,
  Picker,
  Input,
  Space,
  Collapse,
  Checkbox
} from "antd-mobile/es";
import {
  DownCircleOutline,
  RightOutline
} from "antd-mobile-icons";
import CustomCalendar from "./../../components/CustomCalendar";
import ColorIndicator from "./../../components/ColorIndicator";
import CustomSpinner from "./../../components/CustomSpinner";
import Menu from "./../../components/Menu";
import VisualBlock from "./../../components/VisualBlock";
import EmptyBlock from "./../../components/EmptyBlock";
import { ReactComponent as EditIcon } from "./../../img/edit.svg";
import { ReactComponent as ResetIcon } from "./../../img/reset.svg";
import { ReactComponent as CloseIcon } from "./../../img/close.svg";
import "./style.css";

const ListItemLoading = (props) => (<div>
  <List.Item>
    <DotLoading color="primary" />
  </List.Item>
</div>);

const Calendar = (props) => {
  const { t, i18n } = useTranslation();
  const currentHotelId = useSelector(hotelId);
  const prevHotel = usePrevious(currentHotelId); 
  const currentHotelInfo = useSelector(hotelInfo);
  const integrations = useSelector(hotelIntegrations);

  const [ selectedDate, setSelectedDate ] = useState(moment());
  const [ filters, setFilters ] = useState({});

  const prevSource = usePrevious(filters.price_source_id);
  const prevRate = usePrevious(filters.rate);

  const skip = !currentHotelId || !filters.price_source_id || ((prevHotel === currentHotelId && !prevRate && filters.rate) ||
    (prevHotel !== currentHotelId && filters.price_source_id === prevSource));

  const {
    hotelData,
    categoriesData,
    currentFilters,
    availableFilters,
    calendarPending,
    calendarError
  } = useGetCalendarQuery({
      url: `${selectedDate.format("YYYY")}/${selectedDate.format("MM")}`,
      data: {
        hotel_id: currentHotelId,
        filters: {
          category: "all_categories",
          ...filters
        }
      }
    },
    {
      selectFromResult: ({ data, isFetching, error }) => ({
        calendarPending: isFetching,
        calendarError: error,
        hotelData: data?.hotel_data ?? {},
        categoriesData: data?.categories_data ?? [],
        currentFilters: data?.current_filters ?? {},
        availableFilters: data?.available_filters ?? {}
      }),
      skip
    },
  );

  const updateSelected = (categories, value = false) =>
    _.uniq(categories.filter(item => item.rate_plan_id)
      .map(item => item.rate_plan_id))
        .reduce((newObj, item) => {
          newObj[item] = value;
          return newObj;
        }, {});

  const [ savePrice, { savePricePending } ] = useSaveSelfPriceMutation({
    selectFromResult: ({ isLoading }) => ({
      savePricePending: isLoading
    })
  });

  const [ sendPrices, { sendPriceError, sendPricesSuccess, sendPricesPending, reset } ] = useSendPricesMutation({
    selectFromResult: ({ isLoading, error, isSuccess }) => ({
      sendPricesPending: isLoading,
      sendPriceError: error,
      sendPricesSuccess: isSuccess,
    }),
  });

  const [ all, setAll ] = useState(false);  
  const [ editingDate, setEditingDate] = useState(false);
  const [ selectedCategories, setSelectedCategories ] = useState(updateSelected(categoriesData));
  const [ pickerData, setPickerData ] = useState(null);

  useEffect(() => {
    document.title = `hotellab - ${t("headers.calendar")}`;
  }, [i18n.language]);

  useEffect(() => {
    if (currentHotelId) {
      setFilters({
        price_source_id: currentHotelInfo.price_sources?.[0]?.id
      });
      reset();
      setAll(false);
    }
  }, [currentHotelId]);

  useEffect(() => {
    if (!_.isEmpty(categoriesData)) {
      setSelectedCategories(updateSelected(categoriesData));
      setAll(false);
    }
  }, [JSON.stringify(categoriesData)])

  const changeFilter = (filters, url) => {
    setFilters(filters);
    reset();
  }

  const handleSavePrice = (ratePlanId, price) => {
    const url = `${currentHotelId}`;
    const source = currentFilters.price_source_id;

    const data = {
      price_source_id: source,
      data: [{
        rate_plan_id: ratePlanId,
        prices: [{
          date: selectedDate.format("YYYY-MM-DD"),
          price
        }]
      }],
    };

    savePrice({ url, data }).unwrap().then(() => {
      setEditingDate(false);
      /*reset();*/
    });
  }

  const updatePrices = () => {
    let planList = {};
    for (let item in selectedCategories) {
     if (selectedCategories[item]) {
        planList[item] = [selectedDate.format("YYYY-MM-DD")];
      }
    };
    const data = {
      rate_plan_list: planList,
      filters: {
        price_source_id: currentFilters.price_source_id,
        all_categories: all
      }
    };
    sendPrices({ url: currentHotelId, data }).unwrap().then(() => {
      setSelectedCategories(updateSelected(categoriesData));
      setAll(false);
    });
  }

  const changeSelectedCategory = (category) => {
    setSelectedCategories((prevSelected) => {
      if (prevSelected[category.rate_plan_id]) {
        setAll(false);
      }
      if (!prevSelected[category.rate_plan_id] && !_.isEmpty(categoriesData) &&
      Object.values(selectedCategories)
        .filter(elem => elem === true)
        .length === categoriesData.length - 1) {
        setAll(true);
      }
      return ({
        ...prevSelected,
        [category.rate_plan_id]: !prevSelected[category.rate_plan_id]
      });
    });
  }

  const changeAll = () => setAll((prevAll) => {
    setSelectedCategories(updateSelected(categoriesData, !prevAll));
    return !prevAll;
  });

  return (<>
  <Menu title={t("headers.calendar")} />
  <div className="main-container calendar">

    {!_.isEmpty(availableFilters) && !calendarPending ? <List>
      {[
        {
          id: "source",
          title: t("source"),
          value: currentFilters.price_source_id,
          columns: availableFilters.price_sources.map((item) => ({
            value: item.id,
            label: item.title,
          })),
          onConfirm: (v) => {
            if (v[0] !== currentFilters.price_source_id) {
              changeFilter({
                price_source_id: +v[0],
                rate: currentFilters.rate,
                person_capacity: currentFilters.person_capacity
              });
            }
          }
        },
        {
          id: "rate",
          title: t("rate"),
          value: currentFilters.rate,
          columns: availableFilters.rates.map((item) => ({
            value: item,
            label: item,
          })),
          onConfirm: (v) => {
            if (v[0] !== currentFilters.rate) {
              changeFilter({
                price_source_id: currentFilters.price_source_id,
                rate: v[0],
                person_capacity: currentFilters.person_capacity
              });
            }
          }
        }
      ].map((item) => (<List.Item
        key={item.id}
        arrow={<RightOutline />}
        extra={item.columns.find(el => el.value === item.value)?.label}
        onClick={() => setPickerData(item)}>
          {item.title}
        </List.Item>))}

      {pickerData && <Picker
        title={pickerData.title}
        value={[ pickerData.value ]}
        columns={[ pickerData.columns ]}
        visible={pickerData}
        onCancel={() => setPickerData(null)}
        onConfirm={(v) => {
          pickerData.onConfirm(v);
          setPickerData(null)
        }} />}
      <List.Item extra={<Stepper
        step="1"
        value={currentFilters.person_capacity}
        min={Math.min(...availableFilters.person_capacity)}
        max={Math.max(...availableFilters.person_capacity)}          
        onChange={(v) => {
          if (v !== currentFilters.person_capacity) {
            changeFilter({
              price_source_id: currentFilters.price_source_id,
              rate: currentFilters.rate,
              person_capacity: +v
            });
          }
        }}
        />}>
        <span>{t("numOfPers")}&nbsp;</span>
      </List.Item>
    </List>
    :
    <List>
      <ListItemLoading />
      <ListItemLoading />
      <ListItemLoading />
    </List>}

    <Card title="" className="picker">
      <ColorIndicator width="100%" />
      {calendarPending ? <CustomSpinner height="400px" />
        :
        <CustomCalendar
        selectedDate={selectedDate}
        setSelectedDate={(date, changingMonth) => {
          setSelectedDate(date);
          if (changingMonth) {
            changeFilter({
              price_source_id: currentFilters.price_source_id,
              rate: currentFilters.rate,
              person_capacity: currentFilters.person_capacity
            }, `${date.format("YYYY")}/${date.format("MM")}`)
          }
        }}
        tableData={hotelData}
        t={props.t}
      />}
    </Card>

    {sendPricesSuccess && <NoticeBar
      className="nb-positive"
      content={t("updatedRates")}
      color="alert"
      closeable
      icon={<DownCircleOutline />} />}

    {sendPriceError && <NoticeBar
      className="nb-negative"
      content={sendPriceError.status === 422 ? t("notConf") : `Error: ${sendPriceError.data.message}`}
      color="alert" />}

    {!calendarPending && !calendarError && !integrations && <NoticeBar
      className="nb-negative"
      content={t("notConf")}
      color="alert" />}

    <Button
      color="primary"
      style={{ width: "100%", marginBottom: "15px" }}
      size="large"
      loading={sendPricesPending}
      disabled={sendPricesPending || savePricePending || calendarPending || !integrations ||
        _.isEmpty(selectedCategories)
        || !Object.values(selectedCategories).some((item) => item === true)}
        onClick={() => updatePrices()}>
      {t("updateRates")}
    </Button>

    {!_.isEmpty(categoriesData.filter(item => item.rate_plan_id)) && <Checkbox
      checked={all}
      style={{ margin: "15px 0 30px" }}
      onChange={changeAll}>
      {t("updateRatesForAll")}
    </Checkbox>}

    {calendarPending && <div style={{ marginTop: "60px" }}><CustomSpinner withText /></div>}

    {!calendarPending && calendarError && <div style={{ marginTop: "60px" }}>
      <EmptyBlock text={`Error: ${calendarError.data.message}`} /></div>}

  {!calendarPending && !calendarError && <>
    <Card title="" className="total">
      <p className="date-title">{selectedDate.format("ddd DD.MM.YY")}</p>
      <table className="data-table">
        <tbody>
          <tr className="category-item">
            <td>{t("load")}</td>
            <td>{hotelData[selectedDate.format("YYYY-MM-DD")] && hotelData[selectedDate.format("YYYY-MM-DD")].hotel_occupancy !== null ?
              `${hotelData[selectedDate.format("YYYY-MM-DD")].hotel_occupancy}%` : t("n_a")}</td>
            <td><VisualBlock value={hotelData[selectedDate.format("YYYY-MM-DD")] ?
              hotelData[selectedDate.format("YYYY-MM-DD")].hotel_occupancy : 0} /></td>
          </tr>

          <tr className="category-item">
            <td>{t("demIndex")}</td>
            <td>{hotelData[selectedDate.format("YYYY-MM-DD")] && hotelData[selectedDate.format("YYYY-MM-DD")].total_demand_index !== null ?
              `${hotelData[selectedDate.format("YYYY-MM-DD")].total_demand_index}%` : t("n_a")}</td>
            <td><VisualBlock value={hotelData[selectedDate.format("YYYY-MM-DD")] ?
              hotelData[selectedDate.format("YYYY-MM-DD")].total_demand_index : 0} /></td>
            <td/>
          </tr>
        </tbody>
      </table>
    </Card>
    { categoriesData.map((category, ind) => {
      const item = !_.isEmpty(category.data) && category.data[selectedDate.format("YYYY-MM-DD")];
      const disabledCheck = selectedDate.startOf("day").isBefore(moment().startOf("day")) || !integrations;
      let categoryParams = item ?
      [
        {
          title: t("load"),
          value: (item.category_occupancy || item.category_occupancy === 0) ?
            `${item.category_occupancy}%` : t("n_a"),
          visualValue: item.category_occupancy
        },
        {
          title: t("demIndex"),
          value: (item.demand_index || item.demand_index === 0) &&`${item.demand_index}%`,
          visualValue: item.demand_index
        },
        {
          title: t("salesRates"),
          value: item.sold_rooms
        },
        {
          title: t("medianCompetitors"),
          value: (item.competitors_median || item.competitors_median === 0) &&
            formatMoney(item.competitors_median)
        },
        {
          title: t("medianChange"),
          value: (item.competitors_median_change || item.competitors_median_change === 0) &&
            formatMoney(item.competitors_median_change)
        },
      ]
      :
      [];

      const classForRec = classNames({
        "rec-price": true,
        "dyn-positive": item && item.diff === 1,
        "dyn-negative": item && item.diff === -1,
        "self-price": item && item.self_price && item.self_price !== item.recommended_price
      });

      return (<React.Fragment key={uniqid()}>{item && <Card title="" className="category-item">
      <Space justify="between" wrap>
        <Space align="center" wrap>
          {(!editingDate[ind] && editingDate[ind] !== "") && <>{category.rate_plan_id ? <Checkbox
            disabled={disabledCheck}
            checked={selectedCategories[category.rate_plan_id]}
            onChange={(v) => changeSelectedCategory(category)}>
            <span
              onClick={(v) => {
                if (!disabledCheck) {
                  changeSelectedCategory(category);
                }
              }}
              className={classForRec}>
              {(item.self_price || item.recommended_price) && formatMoney(item.self_price || item.recommended_price)}
            </span>
          </Checkbox>
          :
          <span className={classForRec}>
            {(item.self_price || item.recommended_price) && formatMoney(item.self_price || item.recommended_price)}
          </span>}

          {item.self_price && item.recommended_price && <span className={classNames({
              "hint-price": true,
              "dyn-positive": item.diff === 1,
              "dyn-negative": item.diff === -1,
            })}>
            {formatMoney(item.recommended_price)} {item.recommended_price === item.self_price ? "" : item.recommended_price > item.self_price ? "↗" : "↘"}
          </span>}
        </>}

        {category.edit_price && !disabledCheck &&
          <Space>
            {(editingDate[ind] || editingDate[ind] === "") && <Input
              type="number"
              autoFocus={editingDate[ind]}
              value={editingDate[ind]}
              onChange={(v) => setEditingDate({
                [ind]: v
              })}
            />}

            <button
              className="invisible-button invisible-button-mr"
              onClick={!editingDate[ind] ?
                () => setEditingDate({
                  [ind]: item.self_price || item.recommended_price || ""
                })
                :
                () => ((+editingDate[ind] === item.self_price) || (!item.self_price && (+editingDate[ind] === item.recommended_price))) ?
                  setEditingDate(false)
                  :
                  handleSavePrice(category.rate_plan_id, +editingDate[ind])}>
                <EditIcon />
            </button>

            {!(editingDate[ind] || editingDate[ind] === "") && item.self_price && <button
              className="invisible-button invisible-button-mr"
              onClick={() => handleSavePrice(category.rate_plan_id, null)}>
                <ResetIcon />
            </button>}

            {(editingDate[ind] || editingDate[ind] === "") && <button
              className="invisible-button invisible-button-mr"
              onClick={() => setEditingDate(false)}>
                <CloseIcon
                  style={{
                    width: "27px",
                    height: "27px",
                    padding: "5px"
                  }} />
            </button>}
          </Space>}
        </Space>
        {item.current_price && <div className="cur-price-wrapper">
          <span>{t("yourPrice")}</span>
          <span className="cur-price">{formatMoney(item.current_price)}</span>
        </div>}
      </Space>

      <Collapse defaultActiveKey={ind === 0 ? [`${ind}`] : []}>
        <Collapse.Panel key={`${ind}`} title={<span>{category.name}</span>}>
          <List>
            <List.Item>
              <table className="data-table">
                <tbody>
                  {categoryParams.map((param, id) => (
                    <tr key={id}>
                      <td>{param.title}</td>
                      <td>{param.value}</td>
                      {param.visualValue ? <td><VisualBlock value={param.visualValue} /></td> : ""}
                    </tr>
                  ))}
                </tbody>
              </table>
            </List.Item>
          </List>
        </Collapse.Panel>
      </Collapse>

    </Card>}
  </React.Fragment>);})}</>}
</div></>);
}

export default Calendar;

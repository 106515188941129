import { api } from "./api";

export const hotelsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getHotels: builder.query({
      query: (data) => ({
        url: "/hotels",
        method: "GET",
      }),
      keepUnusedDataFor: 5,
      transformResponse: (response) => response.data.map((item, ind) => ({
        ind,
        ...item
      })),
      // The 2nd parameter is the destructured `QueryLifecycleApi`
      /*async onQueryStarted(
        arg,
        {
          dispatch,
          getState,
          extra,
          requestId,
          queryFulfilled,
          getCacheEntry,
          updateCachedData,
        }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(setHotel(data[0]));
          } catch {
            throw new Error("Error: setHotel");
          }
        },*/
    }),
  }),
  overrideExisting: false,
});

export const { useGetHotelsQuery } = hotelsApi;
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import store from "./redux/store";
import App from "./App";
import reportWebVitals from './reportWebVitals';
import moment from "moment";
import "moment/locale/ru";
import { ConfigProvider } from "antd-mobile/es";
import en from "antd-mobile/es/locales/en-US";
import ru from "antd-mobile/es/locales/ru-RU";

const initLang = localStorage.getItem('i18nextLng');
moment.locale(initLang && initLang.indexOf("ru") !== -1 ? "ru" : "en-gb");

if (initLang.indexOf("ru") !== -1) {
  moment.updateLocale("ru", {
    weekdaysShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"]
  });
  moment.updateLocale("ru", {
    months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]
  });
}

const reactRoot = createRoot(document.getElementById("root"));

reactRoot.render(
  /*<React.StrictMode>*/
    <Provider store={store}>
      <ConfigProvider locale={localStorage.getItem("i18nextLng").indexOf("ru") !== -1 ? ru : en}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  /*</React.StrictMode>*/,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { configureStore } from "@reduxjs/toolkit";
import { api } from "./features/api/api";
import currentSlice from "./features/currentSlice";
import authSlice from "./features/authSlice";

export default configureStore({  
  reducer: {
    auth: authSlice,
    current: currentSlice,    
    [api.reducerPath]: api.reducer,    
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(api.middleware)
});
const ru = {
  loadingData: "Загрузка данных...",
  login: "Вход в систему",
  emailOrUsername: "Эл. почта или имя пользователя",
  password: "Пароль",
  forgotPass: "Забыли пароль?",
  loginIncorrect: "Логин или пароль неверен",
  signin: "Войти",
  firstInHL: "Впервые в Hotellab?",
  reg: "Зарегистрируйтесь",
  privPolicy: "Политика конфиденциальности",
  contactUs: "Свяжитесь с нами",
  currencyAnl: "Валюта аналитики",
  currencyCln: "Валюта календаря",
  currHotel: "Текущий отель",
  n_a: "Н/Д",

  headers: {
    dashboard: "Дашборд",
    calendar: "Календарь спроса",
    logout: "Выйти",
    fullVers: "Перейти на полную версию"
  },

  filters: {
    by_date: "на сегодня (календарный день)",
    by_dow: "на сегодня (день недели)",
    on_final: "финал (календарный день)",
    by_dow_on_final: "финал (день недели)",
  },

  rev: "Доход",
  load: "Загрузка",
  vs: "К",
  forYear: "году",

  breakfastIncluded: "С завтраком",
  breakfastWithout: "Без завтрака",
  halfBoard: "Полупансион",
  fullBoard: "Пансион",
  return: "Возвратный",
  unreturn: "Невозвратный",
  minimum: "Минимальный",

  source: "Источник",
  rate: "Тариф",
  numOfPers: "Количество человек",
  updateRates: "Отправить цены",
  updateRatesForAll: "Отправить цены для всех категорий",
  updatedRates: "Цены успешно обновлены",
  notConf: "Интеграция отправки цены не настроена",
  demIndex: "Индекс спроса",
  yourPrice: "Ваша цена",
  medianChange: "Изменение медианы",
  sinceYstd: "(со вчерашнего дня)",
  medianCompetitors: "Медиана конкурентов",
  salesRates: "Темпы продаж",

  days: "дней"
}

export default ru;

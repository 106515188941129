import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import moment from "moment";
import uniqid from "uniqid";
import { isEmpty } from "lodash";
import { formatMoney } from "./../../helpers/utils";
import { usePrevious } from "./../../helpers/customHooks";
import { useGetDashboardQuery } from "../../redux/features/api/dashboardApi";
import { hotelId } from "../../redux/features/currentSlice";
import { Grid, Space, Card, Button } from "antd-mobile/es";
import { Column } from '@ant-design/charts';
import Menu from "./../../components/Menu";
import EmptyBlock from "./../../components/EmptyBlock";
import CustomSpinner from "./../../components/CustomSpinner";
import "./style.css";

const daysFilter = [ 7, 14, 30 ];
const defaultDays = 7;
const defaultFilter = "by_date";

const Dashboard = (props) => {
  const { t, i18n } = useTranslation();
  const currentHotel = useSelector(hotelId);
  const prevHotel = usePrevious(currentHotel);

  const [ days, setDays ] = useState(defaultDays);
  const [ filter, setFilter ] = useState(defaultFilter);

  const skip = !currentHotel || (currentHotel !== prevHotel && (days !== defaultDays || filter !== defaultFilter));

  const { data, isFetching, isError, error } = useGetDashboardQuery({
    hotel_id: currentHotel,
    period: days,
    compare_by: filter
  },
  {
    skip
  });

  useEffect(() => {
    setDays(defaultDays);
    setFilter(defaultFilter);
  }, [currentHotel]);

  useEffect(() => {
    document.title = `hotellab - ${t("headers.dashboard")}`;
  }, [i18n.language]);

  const getChartConfig = (data, title) => (
    {
      data: data.map(item => ({
        name: moment(item.key).format("ddd DD.MM"),
        value: item.value
      })),
      height: 190,
      color: "#5553CE",
      xField: "name",
      yField: "value",
      tooltip: {
        formatter: (tlp) => ({
          name: title,
          value: title !== t("load") ? formatMoney(tlp.value) : tlp.value
        })
      },
      xAxis: {
        label: {
          style: {
            textAlign: "end",
          },
          rotate: 5.5
        },
      },
      yAxis: {
        label: {
          formatter: (v) => title !== t("load") ? formatMoney(v) : v
        },
      },
      intervalPadding: 15,
      meta: {
        name: { alias: "Date" },
        value: { alias: title },
      },
    }
  )

  const renderComparisonRow = (value, diff) => (
    <Grid columns={1}>
      <Space className="dash-params-wrapper">
        <p className="dash-params">{formatMoney(value)}</p>
        <p className={classNames({
          "dyn-positive": diff > 0,
          "dyn-negative": diff < 0
        })}>
          <span className="dash-dyn-pers">
            {diff > 0 && `↗ +${diff}%`}
            {diff < 0 && `↘ ${diff}%`}
            {diff === 0 && `${diff}%`}
          </span>
          <span className="dash-dyn-comp">{t("vs")} {moment().subtract(1, "year").format('YYYY')} {t("forYear")}</span>
        </p>
      </Space>
    </Grid>
  );

  const renderCardTitle = (title) => (<>
    <p className="dash-card-title">{title}</p>
    <p className="dash-card-filter">{t(`filters.${filter}`)}</p>
  </>);

  return (<>
    <Menu
      title={t("headers.dashboard")}
      currentFilter={filter}
      filterValues={
        [{
          value: "by_date",
          title: t("filters.by_date")
        }, {
          value: "by_dow",
          title: t("filters.by_dow")
        }, {
          value: "on_final",
          title: t("filters.on_final")
        }, {
          value: "by_dow_on_final",
          title: t("filters.by_dow_on_final")
        }]
      }
      setFilter={(value) => setFilter(value)}
    />
    <div className="main-container dash">
      <Grid columns={3} gap={10}>
        { daysFilter.map((value) => <Grid.Item key={uniqid()}>
          <Button
            fill={days === value ? "solid" : "outline"}
            color={days === value ? "primary" : "default"}
            style={{ width: "100%" }}
            onClick={() => setDays(value)}>
              {value} {t("days")}
          </Button>
        </Grid.Item>) }
      </Grid>

      <Card title={renderCardTitle(t("load"))}>
          {!isFetching && isError && <EmptyBlock text={`Error: ${error.data.message}`} />}
          {isFetching && <CustomSpinner />}

          {!isFetching && !isError && !isEmpty(data) && <>
            {renderComparisonRow(data.occupancy.value, data.occupancy.diff)}
            <Column {...getChartConfig(data.occupancy.details, t("load"))}/>
          </>}
      </Card>

      <Card title={renderCardTitle("ADR")}>         
          {!isFetching && isError && <EmptyBlock text={`Error: ${error.data.message}`} />}
          {isFetching && <CustomSpinner />}

          {!isFetching && !isError && !isEmpty(data) && <>
            {renderComparisonRow(data.adr.value, data.adr.diff)}
            <Column {...getChartConfig(data.adr.details, "ADR")}/>
          </>}
      </Card>

      <Card title={renderCardTitle("RevPAR")}>          
          {!isFetching && isError && <EmptyBlock text={`Error: ${error.data.message}`} />}
          {isFetching && <CustomSpinner />}

          {!isFetching && !isError && !isEmpty(data) && <>
            {renderComparisonRow(data.revpar.value, data.revpar.diff)}
            <Column {...getChartConfig(data.revpar.details, "RevPAR")} />
          </>}
      </Card>

      <Card title={renderCardTitle(t("rev"))}>         
          {!isFetching && isError && <EmptyBlock text={`Error: ${error.data.message}`} />}
          {isFetching && <CustomSpinner />}

          {!isFetching && !isError && !isEmpty(data) && <>
            {renderComparisonRow(data.revenue.value, data.revenue.diff)}
            <Column {...getChartConfig(data.revenue.details, t("rev"))} />
          </>}
      </Card>
    </div></>
  );
}

export default Dashboard;

import { api } from "./api";

export const dashboardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDashboard: builder.query({
      query: (data) => ({
        url: "/mobile/dashboard",
        method: "POST",
        body: data,
      }),
      // configuration for an individual endpoint, overriding the api setting
      keepUnusedDataFor: 300,
      transformResponse: (response) => response.data
    }),
  }),
  overrideExisting: false,
});

export const { useGetDashboardQuery } = dashboardApi;
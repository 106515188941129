import React, { useState } from "react";
import uniqid from "uniqid";
import moment from "moment";
import classNames from "classnames";
import { Badge, Space } from "antd-mobile/es";
import "./style.css";

const getTableConfig = function(time) {
  const config = {
    from: moment(time).day(),
    pmc: moment(time).subtract(1, "day").date(), //the last day of the prev month
    count: moment(time).daysInMonth(),
  };
  config.rows = (config.from + config.count - 1) / 7;
  if (config.from === 0) {
    config.rows += 1;
  }
  return config;
}

const CustomCalendar = (props) => {
  const { tableData } = props;
  const [ initDate, setInitDate ] = useState(moment(props.selectedDate));
  const [ tableConfig, setTableConfig ] = useState(getTableConfig(moment(props.selectedDate).startOf("month"))/*tableConfigInit*/);

  const nextMonth = () => {
    const nextInit = moment(initDate).add(1, "month");
    setInitDate(nextInit);
    setTableConfig(getTableConfig(moment(nextInit)));
    props.setSelectedDate(nextInit, "next");
  };

  const prevMonth = () => {
    const prevInit = moment(initDate).subtract(1, "month");
    setInitDate(prevInit);
    setTableConfig(getTableConfig(moment(prevInit).valueOf()));
    props.setSelectedDate(prevInit, "prev");
  };

  const getBadgeColor = (load) => {
    if (load > 70) {
      return "#cef8f1";
    } else if (load > 30 && load <=70) {
      return "#fbf6cd";
    }
    else {
      return "#fbcdd6";
    }
  }

  const parseDate = (cy, cm, cd = 1) => {
    const year = cy;
    const month = cm + 1 >= 10 ? cm + 1 : `0${cm + 1}`;
    const day = cd >= 10 ? cd : `0${cd}`;
    return `${year}-${month}-${day}`;
  }

  const config = tableConfig;
  const tableRows = [];

  let cd = -config.from + 1;

  if (cd === 1) {
    cd = -6;
  };

  const cy = initDate.year();
  const cm = initDate.month();

  for (let i = 0; i < config.rows; i++) {
    const dates = [];
    for (let j = 0; j < 7; j++) {
      cd++;
      let date = "";
      let currentDate = false;
      let withAction = false;
      let dateClassName = "";
      let indicator = false;
      let style = {
        zIndex: 2,
        backgroundColor: "transparent"
      };

      if (cd > 0 && cd <= config.count) {
        date = cd;
        const parsedDate = parseDate(cy, cm, date);
        const data = tableData[parsedDate] ? tableData[parsedDate] : {};
        dateClassName = tableData[parsedDate] &&
        moment(parsedDate).isAfter(moment().subtract(1, "days")) && tableData[parsedDate].mismatch ?
          "dp-current-month dyn-positive" : "dp-current-month";
        currentDate = parsedDate === props.selectedDate.format("YYYY-MM-DD");
        withAction = true;

        indicator = data.hotel_occupancy || 10;
        if (indicator) {
          style.backgroundColor = getBadgeColor(indicator);
        }
      } else if (cd <= 0) {
        date = config.pmc + cd;
        dateClassName = "dp-another-month";
      } else {
        date = cd - config.count;
        dateClassName = "dp-another-month";
      }

      const parsedDate = parseDate(cy, cm, date);

      dates.push(<td
        key={uniqid()}
        className={classNames({
          [dateClassName]: true,
          "dp-td": true
        })}
        onClick={() => {
          if (withAction) {
            props.setSelectedDate(moment(parsedDate));
          }
        }}>
          <div className={classNames({
            "dp-date": true,
            "dp-date-current": currentDate,
          })}>{date}</div>
          <Badge style={style} content={Badge.dot} />
        </td>);
      }
      tableRows.push(<tr key={uniqid()}>{dates}</tr>);
    }

    return (<>
      <Space className="dp-title" justify="between" align="center">
        <button onClick={prevMonth} className="invisible-button invisible-button-mr dp-custom-prev" />
        <span className="dp-title-month">{moment(parseDate(cy, cm)).format("MMMM YYYY")}</span>
        <button onClick={nextMonth} className="invisible-button invisible-button-mr dp-custom-next" />
      </Space>
      <table className="dp-table">
        <thead>
          <tr>
            {[1,2,3,4,5,6,0].map((val) => (
              <th
                key={uniqid()}
                className="dp-th">
                {moment().day(val).format("ddd")}
              </th>))}
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
    </>);
}

export default CustomCalendar;
